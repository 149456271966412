import React from 'react'
import Sicredi from '../assets/imgs/logo-1.jpg'
import Sebrae from '../assets/imgs/logo-2.jpg'
import RsGaranti from '../assets/imgs/logo-3.jpg'
import Unicred from '../assets/imgs/logo-6.jpg'
import SantaMaria from '../assets/imgs/logo-7.jpg'

const CcxFooter = () => {
  return (
    <section id="footer">
      <div className="container">
        <div className="logos">
          <a
            href="https://www.sicredi.com.br/coop/regiaocentro"
            target={'_blank'}
          >
            {' '}
            <img src={Sicredi} alt="Logo da Sicredi" />
          </a>
          {/* <a
            href="https://www.unicred.com.br/pontocapital/institucional/entidade/unicred-ponto-capital"
            target={'_blank'}
          >
            {' '}
            <img src={Unicred} alt="Logo da Unicred" />
          </a> */}
          <a href="https://www.santamaria.rs.gov.br/" target={'_blank'}>
            {' '}
            <img src={SantaMaria} alt="Logo da Prefeitura de Santa Maria" />
          </a>
          <a href="https://sebraers.com.br" target={'_blank'}>
            {' '}
            <img src={Sebrae} alt="Logo do Sebrae" />
          </a>
          <a href="https://www.rsgaranti.org.br/" target={'_blank'}>
            {' '}
            <img src={RsGaranti} alt="Logo da RS Garanti" />
          </a>
        </div>
      </div>
    </section>
  )
}

export default CcxFooter
